<template>
<div id="app">
    <transition name="fade" mode="out-in">
        <router-view id="view" />
    </transition>
</div>
</template>

<script>
export default {
    name: 'App'
}
</script>
  
<style>
html,
body {
    margin: 0;
    height: 100%;
}

#app {
    font-family: "PT Sans", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000644;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#view {
    height: 100%
}

.fade-enter-active,
.fade-leave-active {
    transition: all .3s ease;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

.fade-enter {
    max-height: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.fade-enter-to {
    max-height: 80px;
}
</style>
