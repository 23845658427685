import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    collab : {}
  },
  mutations: {
    setCollab(state, collab){
      state.collab = collab;
    }
  },
  actions: {
  },
  modules: {
  }
})
